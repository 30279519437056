import React from 'react';

const HelpScreen: React.FC = () => (
  <div className="p-6">
    <h3 className="text-2xl font-semibold mb-4">Help Center</h3>
    <p className="mb-4">Welcome to the Docky.AI Help Center. Here are some frequently asked questions:</p>
    <ul className="list-disc pl-6">
      <li className="mb-2">How do I upload my resume?</li>
      <li className="mb-2">How can I track my job applications?</li>
      <li className="mb-2">What are credits and how do I use them?</li>
      <li className="mb-2">How can I update my account information?</li>
    </ul>
    <p className="mt-4">For more assistance, please contact our support team at support@docky.ai</p>
  </div>
);

export default HelpScreen;