import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Theme script
const themeScript = `
  (function() {
    function getTheme() {
      return localStorage.getItem('theme') || 'system';
    }

    function applyTheme(theme) {
      if (theme === 'system') {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
      } else {
        if (theme === 'dark') {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
      }
    }

    var theme = getTheme();
    applyTheme(theme);

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function() {
      if (getTheme() === 'system') {
        applyTheme('system');
      }
    });
  })();
`;

// Create a script element and inject the theme script
const script = document.createElement('script');
script.innerHTML = themeScript;
document.head.appendChild(script);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);