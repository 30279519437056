import React from 'react';
import { Menu } from 'lucide-react';
import { Button } from './ui/button';
import ThemeToggle from './ThemeToggle';

interface HeaderProps {
  title: string;
  user: {
    name: string;
  };
  toggleSidebar: () => void;
  theme: string;
  setTheme: (theme: string) => void;
}

const Header: React.FC<HeaderProps> = ({ title, user, toggleSidebar, theme, setTheme }) => (
  <header className="bg-background text-foreground p-4 flex justify-between items-center border-b">
    <div className="flex items-center">
      <Button variant="ghost" size="icon" onClick={toggleSidebar} className="mr-4 lg:hidden">
        <Menu className="h-4 w-4" />
      </Button>
      <h2 className="text-xl font-semibold">{title}</h2>
    </div>
    <div className="flex items-center space-x-4">
      <ThemeToggle theme={theme} setTheme={setTheme} />
      <div className="w-8 h-8 bg-primary text-primary-foreground rounded-full flex items-center justify-center text-sm font-medium">
        {user.name[0]}
      </div>
    </div>
  </header>
);

export default Header;