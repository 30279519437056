import React, { useState } from 'react';
import { Upload, FileText, CheckCircle, AlertCircle, ArrowRight } from 'lucide-react';
import { cn } from 'lib/utils';
import { Button } from './ui/button';
import ShinyButton from './magicui/ShinyButton';

const ResumeScreen: React.FC = () => {
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [analysisResult, setAnalysisResult] = useState<AnalysisResult | null>(null);

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleFile = (file: File) => {
    setFile(file);
    // Simulate AI analysis (replace with actual API call)
    setTimeout(() => {
      setAnalysisResult({
        score: 85,
        strengths: ['Strong technical skills', 'Relevant work experience'],
        improvements: ['Add more quantifiable achievements', 'Include a summary section'],
      });
    }, 2000);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Resume Upload & Analysis</h1>
      
      {!file ? (
        <div 
          className={cn(
            "border-2 border-dashed rounded-lg p-12 text-center transition-colors",
            dragActive ? "border-primary bg-primary/10" : "border-gray-300 hover:border-primary"
          )}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <Upload className="w-16 h-16 mx-auto mb-4 text-gray-400" />
          <p className="mb-4 text-lg">Drag and drop your resume here, or</p>
          <label className="cursor-pointer">
            <Button variant="outline">
              Browse Files
              <input type="file" className="hidden" onChange={handleFileInput} accept=".pdf,.doc,.docx" />
            </Button>
          </label>
        </div>
      ) : (
        <div className="space-y-8">
          <div className="flex items-center space-x-4 p-4 bg-secondary rounded-lg">
            <FileText className="w-8 h-8 text-primary" />
            <div>
              <p className="font-semibold">{file.name}</p>
              <p className="text-sm text-gray-500">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
            </div>
          </div>
          
          {analysisResult ? (
            <div className="space-y-6">
              <h2 className="text-2xl font-semibold">AI Analysis Results</h2>
              <div className="p-6 bg-secondary rounded-lg">
                <div className="flex items-center justify-between mb-4">
                  <span className="text-lg font-medium">Resume Score</span>
                  <span className="text-2xl font-bold text-primary">{analysisResult.score}/100</span>
                </div>
                <div className="space-y-4">
                  <AnalysisSection title="Strengths" items={analysisResult.strengths} icon={<CheckCircle className="w-5 h-5 text-green-500" />} />
                  <AnalysisSection title="Areas for Improvement" items={analysisResult.improvements} icon={<AlertCircle className="w-5 h-5 text-yellow-500" />} />
                </div>
              </div>
              <div className="text-center">
                <ShinyButton>
                  Improve Your Resume <ArrowRight className="ml-2 w-4 h-4" />
                </ShinyButton>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mx-auto"></div>
              <p className="mt-4 text-lg">Analyzing your resume...</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface AnalysisResult {
  score: number;
  strengths: string[];
  improvements: string[];
}

const AnalysisSection: React.FC<{ title: string; items: string[]; icon: React.ReactNode }> = ({ title, items, icon }) => (
  <div>
    <h3 className="font-semibold mb-2">{title}</h3>
    <ul className="space-y-2">
      {items.map((item, index) => (
        <li key={index} className="flex items-center space-x-2">
          {icon}
          <span>{item}</span>
        </li>
      ))}
    </ul>
  </div>
);

export default ResumeScreen;