import React, { useState } from 'react';
import { ChevronRight, Upload, Briefcase, Star, ArrowRight } from "lucide-react";
import { cn } from "lib/utils";
import AnimatedGradientText from "components/magicui/animated-gradient-text";
import ShinyButton from "components/magicui/ShinyButton";

const HomeScreen: React.FC = () => {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // Handle the uploaded file here
      console.log("File uploaded:", e.dataTransfer.files[0].name);
    }
  };

  return (
    <div className="flex flex-col space-y-24 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Hero Section */}
      <section className="text-center py-20">
        <h1 className="text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
          Welcome to Candle
        </h1>
        <p className="text-xl mb-8 text-gray-600 dark:text-gray-300">Your AI-powered job search assistant</p>
        <ShinyButton>Get Started <ArrowRight className="ml-2 h-4 w-4" /></ShinyButton>
      </section>

      {/* Upload Section */}
      <section className="py-16">
        <h2 className="text-3xl font-semibold text-center mb-8">Upload Your Resume</h2>
        <div 
          className={cn(
            "border-2 border-dashed rounded-lg p-12 text-center transition-colors",
            dragActive ? "border-primary bg-primary/10" : "border-gray-300 hover:border-primary"
          )}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <Upload className="w-16 h-16 mx-auto mb-4 text-gray-400" />
          <p className="mb-4 text-lg">Drag and drop your resume here, or</p>
          <label className="cursor-pointer bg-primary text-primary-foreground px-6 py-3 rounded-md hover:bg-primary/90 transition-colors">
            Browse Files
            <input type="file" className="hidden" onChange={(e) => console.log("File selected:", e.target.files?.[0]?.name)} />
          </label>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16">
        <h2 className="text-3xl font-semibold text-center mb-12">Our Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <FeatureCard
            icon={<Upload className="w-10 h-10 text-blue-500" />}
            title="Smart Resume Parsing"
            description="Our AI analyzes your resume to highlight your strengths and suggest improvements."
          />
          <FeatureCard
            icon={<Briefcase className="w-10 h-10 text-green-500" />}
            title="Intelligent Job Matching"
            description="Get personalized job recommendations based on your skills and experience."
          />
          <FeatureCard
            icon={<Star className="w-10 h-10 text-yellow-500" />}
            title="Application Tracking"
            description="Keep track of all your job applications and their statuses in one place."
          />
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-20 bg-gray-50 dark:bg-gray-800 rounded-2xl text-center">
        <h2 className="text-4xl font-bold mb-4">Ready to Supercharge Your Job Search?</h2>
        <p className="text-xl mb-8 text-gray-600 dark:text-gray-300">Join thousands of successful job seekers today!</p>
        <AnimatedGradientText>
          <ShinyButton>
            Sign Up Now <ChevronRight className="inline-block ml-2" />
          </ShinyButton>
        </AnimatedGradientText>
      </section>
    </div>
  );
};

const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => (
  <div className="flex flex-col items-center text-center">
    <div className="mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 dark:text-gray-300">{description}</p>
  </div>
);

export default HomeScreen;