import React, { useState, useEffect } from 'react';
import { cn } from './lib/utils';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';
import LanguageSelector from './components/LanguageSelector';
import HomeScreen from './components/HomeScreen';
import ResumeScreen from './components/ResumeScreen';
import ActivityScreen from './components/ActivityScreen';
import AccountScreen from './components/AccountScreen';
import HelpScreen from './components/HelpScreen';

interface User {
  name: string;
  email: string;
  id: string;
  credits: {
    balance: number;
    total: number;
  };
}

const App: React.FC = () => {
  const [screen, setScreen] = useState<string>('home');
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);
  const [theme, setTheme] = useState<string>(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('theme') || 'system';
    }
    return 'system';
  });
  const [user] = useState<User>({
    name: 'Kalsi Gurkanwal',
    email: 'kalsi.gurkanwal@projectcandle.io',
    id: 'user_VRlRJUFwoNZdYpxowhQc4',
    credits: { balance: 50, total: 100 }
  });

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');

    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      root.classList.add(systemTheme);
    } else {
      root.classList.add(theme);
    }

    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const toggleCollapse = () => setSidebarCollapsed(!sidebarCollapsed);

  const renderScreen = () => {
    switch (screen) {
      case 'home': return <HomeScreen />;
      case 'resume': return <ResumeScreen />;
      case 'activity': return <ActivityScreen />;
      case 'account': return <AccountScreen user={user} />;
      case 'help': return <HelpScreen />;
      case 'billing': return <div className="p-6">Billing Screen (to be implemented)</div>;
      default: return <HomeScreen />;
    }
  };

  return (
    <div className="flex h-screen bg-background text-foreground">
      <Sidebar 
        activeScreen={screen} 
        setScreen={setScreen} 
        isOpen={sidebarOpen} 
        toggleSidebar={toggleSidebar}
        isCollapsed={sidebarCollapsed}
        toggleCollapse={toggleCollapse}
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header 
          title={screen.charAt(0).toUpperCase() + screen.slice(1)} 
          user={user} 
          toggleSidebar={toggleSidebar}
          theme={theme}
          setTheme={setTheme}
        />
        <main className="flex-1 overflow-y-auto p-6 bg-background">
          {renderScreen()}
        </main>
        <Footer />
      </div>
      <LanguageSelector />
    </div>
  );
};

export default App;