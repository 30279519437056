import React from 'react';
import { Globe, ChevronDown } from 'lucide-react';

const LanguageSelector: React.FC = () => (
  <div className="absolute bottom-4 left-4 flex items-center text-muted-foreground">
    <Globe className="w-4 h-4 mr-2" />
    <span className="text-sm">English</span>
    <ChevronDown className="w-4 h-4 ml-1" />
  </div>
);

export default LanguageSelector;