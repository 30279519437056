import React from 'react';
import { Sun, Moon, Laptop } from 'lucide-react';
import { Button } from './ui/button';

interface ThemeToggleProps {
  theme: string;
  setTheme: (theme: string) => void;
}

const ThemeToggle: React.FC<ThemeToggleProps> = ({ theme, setTheme }) => {
  const themeOptions = ['light', 'dark', 'system'];
  const icons = { light: Sun, dark: Moon, system: Laptop };
  const Icon = icons[theme as keyof typeof icons];

  const cycleTheme = () => {
    const currentIndex = themeOptions.indexOf(theme);
    const nextIndex = (currentIndex + 1) % themeOptions.length;
    setTheme(themeOptions[nextIndex]);
  };

  return (
    <Button variant="ghost" size="icon" onClick={cycleTheme} aria-label="Toggle theme">
      <Icon className="h-4 w-4" />
    </Button>
  );
};

export default ThemeToggle;