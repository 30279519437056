import React from 'react';

const ActivityScreen: React.FC = () => {
  const applications = [
    { company: 'Tech Co', position: 'Software Engineer', dateApplied: '2024-03-15', status: 'Applied' },
    { company: 'Innovative Inc', position: 'Product Manager', dateApplied: '2024-03-10', status: 'Interview Scheduled' },
    { company: 'Digital Solutions', position: 'UX Designer', dateApplied: '2024-03-05', status: 'Rejected' },
  ];

  return (
    <div className="p-6">
      <h3 className="text-2xl font-semibold mb-4">Job Application Activity</h3>
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-muted">
            <th className="border p-2 text-left">Company</th>
            <th className="border p-2 text-left">Position</th>
            <th className="border p-2 text-left">Date Applied</th>
            <th className="border p-2 text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          {applications.map((app, index) => (
            <tr key={index}>
              <td className="border p-2">{app.company}</td>
              <td className="border p-2">{app.position}</td>
              <td className="border p-2">{app.dateApplied}</td>
              <td className="border p-2">{app.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ActivityScreen;