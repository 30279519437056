import React from 'react';
import { Home, FileText, Briefcase, User, HelpCircle, CreditCard, Menu, ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from './ui/button';

interface SidebarProps {
  activeScreen: string;
  setScreen: (screen: string) => void;
  isOpen: boolean;
  toggleSidebar: () => void;
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ activeScreen, setScreen, isOpen, toggleSidebar, isCollapsed, toggleCollapse }) => {
  return (
    <div className={`bg-background text-foreground h-screen flex flex-col border-r transition-all duration-300 ${
      isOpen ? 'translate-x-0' : '-translate-x-full'
    } ${
      isCollapsed ? 'w-16' : 'w-64'
    } lg:translate-x-0 fixed lg:relative z-10`}>
      <div className="p-4 flex items-center justify-between">
        {!isCollapsed && <h1 className="text-2xl font-bold">Candle</h1>}
        <Button variant="ghost" size="icon" onClick={toggleSidebar} className="lg:hidden">
          <Menu className="h-4 w-4" />
        </Button>
        <Button variant="ghost" size="icon" onClick={toggleCollapse} className="hidden lg:flex">
          {isCollapsed ? <ChevronRight className="h-4 w-4" /> : <ChevronLeft className="h-4 w-4" />}
        </Button>
      </div>
      <nav className="flex-1 space-y-1 p-2">
        <SidebarItem icon={<Home />} label="Home" active={activeScreen === 'home'} onClick={() => setScreen('home')} isCollapsed={isCollapsed} />
        <SidebarItem icon={<FileText />} label="Resume" active={activeScreen === 'resume'} onClick={() => setScreen('resume')} isCollapsed={isCollapsed} />
        <SidebarItem icon={<Briefcase />} label="Activity" active={activeScreen === 'activity'} onClick={() => setScreen('activity')} isCollapsed={isCollapsed} />
        <SidebarItem icon={<User />} label="Account" active={activeScreen === 'account'} onClick={() => setScreen('account')} isCollapsed={isCollapsed} />
        <SidebarItem icon={<HelpCircle />} label="Help" active={activeScreen === 'help'} onClick={() => setScreen('help')} isCollapsed={isCollapsed} />
        <SidebarItem icon={<CreditCard />} label="Billing" active={activeScreen === 'billing'} onClick={() => setScreen('billing')} isCollapsed={isCollapsed} />
      </nav>
    </div>
  );
};

interface SidebarItemProps {
  icon: React.ReactElement;
  label: string;
  active: boolean;
  onClick: () => void;
  isCollapsed: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, label, active, onClick, isCollapsed }) => (
  <Button
    variant={active ? 'secondary' : 'ghost'}
    className={`w-full justify-start ${isCollapsed ? 'px-2' : 'px-3'}`}
    onClick={onClick}
  >
    {React.cloneElement(icon, { className: 'w-4 h-4 mr-2' })}
    {!isCollapsed && <span>{label}</span>}
  </Button>
);

export default Sidebar;