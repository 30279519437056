import React from 'react';

interface User {
  name: string;
  email: string;
  id: string;
  credits: {
    balance: number;
    total: number;
  };
}

interface AccountScreenProps {
  user: User;
}

const AccountScreen: React.FC<AccountScreenProps> = ({ user }) => (
  <div className="p-6">
    <div className="flex items-center mb-6">
      <div className="w-16 h-16 bg-primary text-primary-foreground rounded-full flex items-center justify-center text-2xl font-bold mr-4">
        {user.name[0]}
      </div>
      <div>
        <h3 className="text-xl font-semibold">{user.name}</h3>
        <p className="text-blue-500 cursor-pointer">Edit</p>
      </div>
    </div>
    <p className="text-muted-foreground mb-4">ID: {user.id}</p>
    <div className="mb-6">
      <h4 className="font-semibold mb-2">Account Binding</h4>
      <div className="flex items-center">
        <img src="/api/placeholder/24/24" alt="Google" className="w-6 h-6 mr-2" />
        <span className="text-foreground">Google account</span>
      </div>
      <p className="text-muted-foreground ml-8">{user.email}</p>
    </div>
    <div className="mb-6">
      <h4 className="font-semibold mb-2">Credits</h4>
      <p>Balance: {user.credits.balance} / Total: {user.credits.total}</p>
    </div>
  </div>
);

export default AccountScreen;